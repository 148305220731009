.console-group button
{
    &:focus, &.focus {
        border: 2px solid #000000 !important;
    }

    &:not(:active):not(.active):focus, &:not(:active):not(.active).focus
    {
        background-color: #fff !important;
    }
}