.step {
  position: relative;
}

hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
}