.roundBtn {
    border-radius: 50% !important;
}

.iconBadge {
    position: absolute !important;
    top: -0.1rem !important;
    right: -0.1rem;
    padding: 0.1rem !important;
    border-radius: 50%;
}

.add {
  border-radius: 0 !important;
}
.add:not(:disabled):hover,
.add:not(:disabled):focus {
  background-color: #1c9d5b !important;
  border-color: #1c9d5b !important;
}
.remove {
  border-radius: 0 !important;
}
.remove:not(:disabled):hover,
.remove:not(:disabled):focus {
  background-color: #e51e3d !important;
  border-color: #e51e3d !important;
}