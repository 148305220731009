.lightbox {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  
    background: rgba(0, 0, 0, 0.5);
  
    display: flex;
    align-items: center;
    align-items: center;
  
    visibility: hidden;
    opacity: 0;
  
    transition: opacity ease 0.4s;
  }
  
  .show {
    visibility: visible;
    opacity: 1;
  }
  
  .show_image {
    width: 80vw;
    height: 100vh;
    object-fit: contain;
    margin:auto;
    cursor:zoom-out;
  }
  
  .hide_image {
    cursor:zoom-in;
  }
  