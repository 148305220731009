// Color system
$body-bg: #eaebec;
$body-color: #4a4a49;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #eceeef !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #919aa1 !default;
$gray-700: #55595c !default;
$gray-800: #343a40 !default;
$gray-900: #1a1a1a !default;
$black: #000 !default;

$hero-indigo: #220639 !default;
$hero-blue: #00aae4 !default;
$light-sea-green: #31b7bc !default;
$eucalyptus: #1c9d5b !default;
$moon-yellow: #fab721 !default;
$vivid-violet: #89529c !default;
$alizarin: #e51e3d !default;
$dark-gray: #adadad !default;

$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #fd7e14 !default;
$yellow: #f0ad4e !default;
$green: #4bbf73 !default;
$teal: #20c997 !default;
$cyan: #1f9bcf !default;

$primary: $hero-blue !default;
$secondary: $dark-gray !default;
$success: $eucalyptus !default;
$info: $light-sea-green !default;
$warning: $moon-yellow !default;
$danger: $alizarin !default;
$light: $white !default;
$dark: $gray-800 !default;
$ea: $hero-indigo !default;

$theme-colors: (
  "ea": $hero-indigo,
);

$yiq-contrasted-threshold: 185 !default;

// Fonts

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-base: lighter !default;

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;

$headings-font-weight: lighter !default;

$lead-font-weight: 400 !default;

// Navbar

$navbar-brand-font-size: $font-size-base !default;

$navbar-light-color: rgba($ea, 0.5) !default;
$navbar-light-hover-color: rgba($ea, 0.7) !default;
$navbar-light-active-color: rgba($ea, 0.9) !default;
$navbar-light-disabled-color: rgba($ea, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($ea, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;

// Options

$enable-rounded: false !default;
