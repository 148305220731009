.control {
  position: absolute;
  z-index: 1000;
}
.control-top {
  top: 0;
}
.control-bottom {
  bottom: 0;
}
.control-left {
  left: 0;
}
.control-right {
  right: 0;
}
.control-margin-top-sm {
  margin-top: .5rem;
}
.control-margin-bottom-sm {
  margin-bottom: .5rem;
}
.control-margin-left-sm {
  margin-left: .5rem;
}
.control-margin-right-sm {
  margin-right: .5rem;
}
.control-margin-top-md {
  margin-top: .5rem;
}
.control-margin-bottom-md {
  margin-bottom: .5rem;
}
.control-margin-left-md {
  margin-left: .5rem;
}
.control-margin-right-md {
  margin-right: .5rem;
}
.control-behind-guidance {
  z-index:999;
}