.legend {
    color: #858585;
    background-color: #ffffff;
    padding: 0.75rem 1.25rem;
    text-align: right;
}
.header {
    font-weight: 700;
}
.customList {
    list-style: none;
    margin: 0;
    text-indent: -1.5rem;
    padding: 0 0 0 1.5rem;
}

.listItemRed:after,
.listItemOrange:after,
.listItemGreen:after {
    content: "\25AC\25CF";
    display: block;
    float: right;
    width: 1.5rem;
}

.listItemRed:after {
    color: red;
}
.listItemOrange:after {
    color: orange;
}
.listItemGreen:after {
    color: green;
}

.listItemLine:after {
    content: "\25AC";
    display: block;
    float: right;
    width: 1.5rem;
    color: black;
}
.listItemDashedLine:after {
    content: "\2504";
    display: block;
    float: right;
    width: 1.5rem;
    color: grey;
}
.listItemCircle:after {
    content: "\25CF";
    display: block;
    float: right;
    width: 1.5rem;
    color: black;
}