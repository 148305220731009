.stepItem {
  color: #220639;
  font-weight: 400 !important;
  text-transform: capitalize;
  font-size: 0.875rem !important;
  padding: 0 !important;
  text-align: left !important;
}

.stepItemToggle {
  composes: stepItem;
  font-weight: 400;
  color: #343a40;
}

.stepItemToggle::before {
  text-align: center;
  width: 2rem;
  line-height: 0;
  margin-right: 0.5rem;
  display: inline-block;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease, opacity 0.35s ease;
  transform-origin: 1rem 50%;
  opacity:0;
}

.stepItemToggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
  opacity:1;
}

.expanded::before {
  opacity:1;
}

.frame {
  text-align: center;
  font-weight: 700;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  color: #343a40;
  display: inline-block;
}

.square {
  composes: frame;
  color: #fff;
  background: #220639;
  margin-right: 0.5rem;
}