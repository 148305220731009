@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kwcrq3');
  src:  url('fonts/icomoon.eot?kwcrq3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kwcrq3') format('truetype'),
    url('fonts/icomoon.woff?kwcrq3') format('woff'),
    url('fonts/icomoon.svg?kwcrq3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-joint:before {
  content: "\e971";
}
.icon-bug-solid:before {
  content: "\e970";
}
.icon-check-circle-solid:before {
  content: "\e96e";
}
.icon-times-circle-solid:before {
  content: "\e96f";
}
.icon-existing-mixed:before {
  content: "\e96c";
}
.icon-new-mixed:before {
  content: "\e96d";
}
.icon-cabinet:before {
  content: "\e967";
}
.icon-existing-domestic:before {
  content: "\e968";
}
.icon-existing-non-domestic:before {
  content: "\e969";
}
.icon-new-domestic:before {
  content: "\e96a";
}
.icon-new-non-domestic:before {
  content: "\e96b";
}
.icon-save-solid:before {
  content: "\e966";
}
.icon-overhead-circuit-legend-solid:before {
  content: "\e964";
}
.icon-sub-circuit-legend-alt-solid:before {
  content: "\e965";
}
.icon-map-marker:before {
  content: "\e963";
}
.icon-meter:before {
  content: "\e962";
}
.icon-delete-group:before {
  content: "\e961";
}
.icon-select-group:before {
  content: "\e960";
}
.icon-user-solid:before {
  content: "\e95f";
}
.icon-cable:before {
  content: "\e95e";
}
.icon-heat-solid:before {
  content: "\e95b";
}
.icon-sigma-solid:before {
  content: "\e95c";
}
.icon-tilde-solid:before {
  content: "\e95d";
}
.icon-plus-solid:before {
  content: "\e915";
}
.icon-expand-menu:before {
  content: "\e959";
}
.icon-contract-menu:before {
  content: "\e95a";
}
.icon-bars-solid:before {
  content: "\e916";
}
.icon-cross-solid:before {
  content: "\e914";
}
.icon-paper-plane-solid:before {
  content: "\e958";
}
.icon-bolt-solid:before {
  content: "\e94b";
}
.icon-map-solid:before {
  content: "\e957";
}
.icon-site:before {
  content: "\e954";
}
.icon-underground-cable:before {
  content: "\e955";
}
.icon-redo-solid:before {
  content: "\e956";
}
.icon-node:before {
  content: "\e953";
}
.icon-share-solid:before {
  content: "\e951";
}
.icon-archive-solid:before {
  content: "\e952";
}
.icon-underground-circuit-legend-solid:before {
  content: "\e94e";
}
.icon-sub-circuit-legend-solid:before {
  content: "\e94f";
}
.icon-sub-legend-solid:before {
  content: "\e950";
}
.icon-cog-solid:before {
  content: "\e94d";
}
.icon-charging-station-solid:before {
  content: "\e94c";
}
.icon-edit-solid:before {
  content: "\e94a";
}
.icon-sliders-h-solid:before {
  content: "\e949";
}
.icon-book-open-solid:before {
  content: "\e948";
}
.icon-check-circle-on:before {
  content: "\e944";
}
.icon-legend-solid:before {
  content: "\e943";
}
.icon-check-circle-on-alt:before {
  content: "\e942";
}
.icon-check-circle-off:before {
  content: "\e946";
}
.icon-check-square-off:before {
  content: "\e947";
}
.icon-check-square-on:before {
  content: "\e945";
}
.icon-triangle-solid:before {
  content: "\e940";
}
.icon-circle-solid:before {
  content: "\e941";
}
.icon-print-solid:before {
  content: "\e93f";
}
.icon-caret-down-solid:before {
  content: "\e93e";
}
.icon-info-circle-solid:before {
  content: "\e93c";
}
.icon-question-circle-solid:before {
  content: "\e93d";
}
.icon-info-solid:before {
  content: "\e939";
}
.icon-location-arrow-solid:before {
  content: "\e93a";
}
.icon-question-solid:before {
  content: "\e93b";
}
.icon-battery-three-quarters-solid:before {
  content: "\e936";
}
.icon-chart-line-solid:before {
  content: "\e937";
}
.icon-network-wired-solid:before {
  content: "\e938";
}
.icon-layer-group-solid:before {
  content: "\e935";
}
.icon-all-solid:before {
  content: "\e934";
}
.icon-leaf-solid:before {
  content: "\e932";
}
.icon-seedling-solid:before {
  content: "\e933";
}
.icon-backspace-solid:before {
  content: "\e92e";
}
.icon-undo-solid:before {
  content: "\e931";
}
.icon-snowflake-solid:before {
  content: "\e92f";
}
.icon-sun-solid:before {
  content: "\e930";
}
.icon-file-pdf-solid:before {
  content: "\e92c";
}
.icon-file-solid:before {
  content: "\e92d";
}
.icon-comment-solid:before {
  content: "\e92b";
}
.icon-minus-circle-solid:before {
  content: "\e92a";
}
.icon-spinner-solid:before {
  content: "\e929";
}
.icon-node-large:before {
  content: "\e928";
}
.icon-exclamation-solid:before {
  content: "\e927";
}
.icon-minus-solid:before {
  content: "\e926";
}
.icon-lock-open-solid:before {
  content: "\e925";
}
.icon-play-solid:before {
  content: "\e921";
}
.icon-file-import-solid:before {
  content: "\e922";
}
.icon-file-export-solid:before {
  content: "\e923";
}
.icon-search-solid:before {
  content: "\e924";
}
.icon-connection:before {
  content: "\e920";
}
.icon-lock-solid:before {
  content: "\e91f";
}
.icon-grip-lines-solid:before {
  content: "\e91e";
}
.icon-caret-up-solid:before {
  content: "\e91d";
}
.icon-i-cursor-solid:before {
  content: "\e91c";
}
.icon-calculator-solid:before {
  content: "\e91b";
}
.icon-check-solid:before {
  content: "\e91a";
}
.icon-square-solid1:before {
  content: "\e919";
}
.icon-check-square-regular:before {
  content: "\e917";
}
.icon-check-square-solid:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e912";
}
.icon-chevron-right:before {
  content: "\e913";
}
.icon-node-tool:before {
  content: "\e904";
}
.icon-select:before {
  content: "\e911";
}
.icon-welder:before {
  content: "\e910";
}
.icon-substation-gmt:before {
  content: "\e900";
}
.icon-substation-pmt:before {
  content: "\e901";
}
.icon-underground-cable_:before {
  content: "\e902";
}
.icon-overhead-line:before {
  content: "\e903";
}
.icon-connection1:before {
  content: "\e905";
}
.icon-domestic:before {
  content: "\e906";
}
.icon-small-commercial:before {
  content: "\e907";
}
.icon-large-commercial:before {
  content: "\e908";
}
.icon-industrial:before {
  content: "\e909";
}
.icon-motor:before {
  content: "\e90a";
}
.icon-generator:before {
  content: "\e90b";
}
.icon-evChargePoint:before {
  content: "\e90c";
}
.icon-move:before {
  content: "\e90d";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-delete:before {
  content: "\e90f";
}
