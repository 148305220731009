.legend {
    color: #858585;
    background-color: #ffffff;
    padding: 0.75rem 1.25rem;
    text-align: right;
}
.header {
    font-weight: 700;
}
.customList {
    list-style: none;
    margin: 0;
    text-indent: -1.5rem;
    padding: 0 0 0 1.5rem;
    vertical-align: text-bottom;
}

.legendColor
{
    display: inline-block;
    height: 12px;
    width: 30px;
    margin-left: 5px;
    opacity: 0.8;
}