.options {
    color: #858585;
    background-color: #ffffff;
    padding: 0.75rem 1.25rem;
    text-align: right;
}
.header {
    font-weight: 700;
}
.customList {
    list-style: none;
    margin-left: 1rem;
    padding: 0 0 0 0;
}

.listItem {
    text-align: right;
    vertical-align:middle;
}

.listItemText {
    vertical-align:sub;
}
