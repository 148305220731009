.steps {
  height: calc(100vh - 3rem);
}
.submit {
  border-radius: 0.3rem !important;
}
.submit:not(:disabled):hover,
.submit:not(:disabled):focus {
  background-color: #1c9d5b !important;
  border-color: #1c9d5b !important;
}
