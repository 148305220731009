.iconMarker {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 2rem;
    border-radius: .5rem;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    opacity: 1;
    box-shadow: none;
    color: #ffffff;
}

.roundedIconMarker {
    border-radius: .25rem;
}

.squareIconMarker {
    border-radius: 0;
}

.iconDark {
    background-color: #343a40;
}

.iconConnected {
    background-color: #3388ff;
    /* box-shadow: 0px 0px 6px 6px rgba(51, 136, 255,0.5); */
}

.iconColor {
    color: #3388ff;
}

.iconHighlight {
    background-color: #3388ff;
}

.draw, .select {
    opacity: 1;
    box-shadow: none;
}

.iconShadow {
    box-shadow: 0 6px 12px rgb(0 0 0 / 50%);
}

.iconGlow {
    box-shadow: 0px 0px 6px 6px rgba(51, 136, 255,0.5);
}

.iconBorder {
    border: 2px solid #3388ff;
}
.iconDanger {
    border: 2px solid #ff0000;
    box-shadow: 0px 0px 6px 6px rgba(255, 0, 0, 0.5);
    color: #ff0000;
}