html,
body,
#root,
#map {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Lato', sans-serif;
}

.btn:focus,
.btn.focus {
  text-decoration: none !important;
  box-shadow: none !important;
}

.iosBoxShadow {
  box-shadow: 0 3px 1px rgb(0, 0, 0, 10%), 0 4px 8px rgb(0, 0, 0, 13%),
    0 0 0 1px rgb(0, 0, 0, 2%);
}

/* Transitions */

.steps-enter {
  opacity: 0;
}
.steps-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}
.steps-exit {
  opacity: 1;
}
.steps-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}

.step-text-enter, .step-text-stepItem, .step-text-exit, .step-text-exit-active {
  display: none;
}

.overlay-enter {
  opacity: 0;
}
.overlay-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}
.overlay-exit {
  opacity: 1;
}
.overlay-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}

.sidebar .btn-dark.active{
  background-color: transparent !important;
  border-color: transparent !important;
  color: #3388ff !important;
  box-shadow: 0px 0px 6px 6px rgba(51, 136, 255,0.5) !important;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: inline-block;
  -webkit-animation: anim-rotate 2s infinite linear;
  animation: anim-rotate 2s infinite linear;
}

.leaflet-popup-content-wrapper {
  border-radius: 12;
}

.screenshot-mode {
  visibility: hidden;
}

.leaflet-control-scale {
  margin-right: 3rem !important;
  margin-bottom: .5rem !important;
}
.leaflet-marker-pane { z-index: 9999; }
.leaflet-tooltip { z-index: 9999; }

.leaflet-measure-path-measurement [title="Total length"] {
  left: 0.5rem;
}