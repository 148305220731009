// Bootstrap and its default variables
@import "./eavariables";
@import "~bootstrap/scss/bootstrap";

$primary: $gray-900 !default;

// // Your variable overrides
// $body-bg: #eaebec;
// $body-color: #111;
// $theme-colors: (
//   "new": #220639,
//   "indigo": #220639,
//   "dark": #808080,
//   "light": #999999
// );
// $dropdown-bg: $dark;
// $dropdown-link-color: $light;
// Lux 4.3.1
// Bootswatch
// Variables ===================================================================
//

// $web-font-path: "https://fonts.googleapis.com/css?family=roboto&display=swap" !default;
// @import url($web-font-path);

// Navbar ======================================================================

// .navbar {
//   &-nav {
//     .nav-link {
//       padding-top: 0.715rem;
//       padding-bottom: 0.715rem;
//     }
//   }

//   &-brand {
//     margin-right: 2rem;
//   }
// }

// .bg-primary {
//   background-color: theme-color("primary") !important;
// }

.bg-ea {
  background-color: theme-color("ea") !important;
}

.bg-single-phase {
  background-color: theme-color("single-phase") !important;
}

.bg-two-phase {
  background-color: theme-color("two-phase") !important;
}

.bg-three-phase {
  background-color: theme-color("three-phase") !important;
}

ol,
ul,
dl {
  padding-left: 20px;
}

legend {
  font-size: 1rem;
}

// .bg-light {
//   border: 1px solid rgba(0, 0, 0, 0.1);

//   &.navbar-fixed-top {
//     border-width: 0 0 1px 0;
//   }

//   &.navbar-bottom-top {
//     border-width: 1px 0 0 0;
//   }
// }

// .nav-item {
//   margin-right: 2rem;
// }

// // Buttons =====================================================================

// .btn {
//   font-size: $font-size-sm;

//   &-sm {
//     font-size: 10px;
//   }

//   &-warning {
//     &,
//     &:hover,
//     &:not([disabled]):not(.disabled):active,
//     &:focus {
//       color: $white;
//     }
//   }
// }

// .btn-outline-secondary {
//   border-color: $gray-600;
//   color: $gray-600;

//   &:not([disabled]):not(.disabled):hover,
//   &:not([disabled]):not(.disabled):focus,
//   &:not([disabled]):not(.disabled):active {
//     background-color: $gray-400;
//     border-color: $gray-400;
//     color: $white;
//   }

//   &:not([disabled]):not(.disabled):focus {
//     box-shadow: 0 0 0 0.2rem rgba($gray-400, 0.5);
//   }
// }

// [class*="btn-outline-"] {
//   border-width: 2px;
// }

// .border-secondary {
//   border: 1px solid $gray-400 !important;
// }

// // Typography ==================================================================

// body {
//   font-weight: 200;
//   letter-spacing: 1px;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   letter-spacing: 3px;
// }

// .text-secondary {
//   color: $body-color !important;
// }

// // Tables ======================================================================

// th {
//   font-size: $font-size-sm;
// }

// .table {
//   th,
//   td {
//     padding: 1.5rem;
//   }

//   &-sm {
//     th,
//     td {
//       padding: 0.75rem;
//     }
//   }
// }

.table-custom {
  thead th {
    border-bottom-color: #666;
  }
  th,
  td {
    border-top: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: #666;
  }
  tr:first-child td {
    padding-top: 1rem;
  }
}

.table-custom-ea {
  color: #220639;
  thead th {
    border-bottom-color: #220639;
  }
  th,
  td {
    border-color: #220639;
  }
}

.table-cell-border-none {
  td {
    border: none;
    padding-top: 0em;
    padding-bottom: 0em;
  }
}
// // Forms =======================================================================

// .custom-switch {
//   .custom-control-label {
//     &::after {
//       top: calc(0.15625rem + 2px);
//       left: calc(-2.25rem + 2px);
//       width: calc(1rem - 4px);
//       height: calc(1rem - 4px);
//     }
//   }
// }

// // Navs ========================================================================

// .dropdown-menu {
//   font-size: $font-size-sm;
//   text-transform: none;
// }

// // Indicators ==================================================================

// .badge {
//   padding-top: 0.28rem;

//   &-pill {
//     border-radius: 10rem;
//   }
// }

// // Progress bars ===============================================================

// // Containers ==================================================================

// .list-group-item {
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   .h1,
//   .h2,
//   .h3,
//   .h4,
//   .h5,
//   .h6 {
//     color: inherit;
//   }
// }

// .card {
//   &-title,
//   &-header {
//     color: inherit;
//   }
// }
.hr-divider {
  position: relative;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}
.hr-divider:before {
  position: absolute;
  top: 50%;
  display: block;
  content: "";
  width: 30%;
  height: 1px;
  background-color: #eee;
}
.hr-divider:after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  content: "";
  width: 30%;
  height: 1px;
  background-color: #eee;
}
.hr-divider-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 100%;
  color: inherit;
}
.hr-divider-content {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
  color: #ffffff;
  vertical-align: middle;
  background-color: transparent;
}
.transform-none {
  text-transform: none;
}

.btn {
  // padding to support 32px square button with 16px icon
  padding: 0.1875rem 0.4375rem;
}

.leaflet-conductor-popup {
  z-index: 10500;
}
.leaflet-popup-scrolled {
  border-bottom: 0;
  border-top: 0;
}
.leaflet-popup-separator {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.leaflet-popup-streetview-icon {
  width: 15px;
  margin: 5px;
}
.leaflet-popup-copy-icon {
  width: 25px;
  cursor: pointer;
  margin: 5px;
}

//Focus / Hover / Default overrides
$nien-blue: #0065bd; //NIEN specific color

.sidebar button.bg-light {
  &:hover {
    background-color: $nien-blue !important;
    border-color: $nien-blue !important;
    color: $white;
  }
  &:focus:not(:hover) {
    border: 1px solid $black !important;
    background-color: $white !important;
  }
}

.toolbar button.bg-light
{
  &:hover {
    background-color: $nien-blue !important;
    border-color: $nien-blue !important;
    color: $white;
  }
  &:focus {
    border: 2px solid $black;
  }
}

.steps-btn.btn-link {
  color: $nien-blue;
  text-decoration: none;
  padding: 0.6875rem 0.9375rem;

  &:hover {
    color: $nien-blue;
    background-color: $white;
    text-decoration: none;
  }
}

.zoom {
  &:not(:disabled):hover {
  background-color: $nien-blue !important;
  border-color: $nien-blue !important;
  }
  &:not(:disabled):focus {
    border: 2px solid $white;
  }
}

.square {
  color: $white !important;
  background: $nien-blue !important;
}

.undo-redo
{
  &:not(:disabled):hover {
    background-color: $nien-blue !important;
    border-color: $nien-blue !important;
  }
  &:not(:disabled):focus {
    border: 1px solid $white;
  }
}


.stepItem {
  color: $nien-blue !important;
}