.sidebar {
  position: absolute;
  top: 3rem;
  left: 0;
  right: auto;
  z-index: 999;
  width: 6rem;
  font-size: 0.75rem;
  transition: width 150ms ease-in-out;
}

.sidebar-right {
  left: auto;
  right: 0;
}

.expanded {
  width: 15rem;
}

.sidebar-btns {
  width: 6rem;
  margin-top: -3rem;
}

.sidebar button.bg-light:hover,
.sidebar button.bg-light:focus {
  background-color: #fff !important;
}

.steps-btn.btn-link {
  color: #220639;
  text-decoration: none;
  padding: 0.6875rem 0.9375rem;
}

.steps-btn.btn-link:hover {
  color: #06010b;
  background-color: #fff;
  text-decoration: none;
}
