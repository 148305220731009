.overlay-backdrop.fade {
    opacity: 0;
  }
.overlay-backdrop.show {
    opacity: 0.5;
}
.overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.fade {
    transition: opacity 0.15s linear;
}